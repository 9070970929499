import React from "react";
import Head from "../../components/head.js";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Layout from "../../components/layout";

const Page = () => {
  return (
    <Layout>
      <Head title="David Ohlerking" />
      <h3>David Ohlerking</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Pahrump, NV
      </h4>
      <p>
        <OutboundLink href="https://www.davidohlerking.com/">
          Website
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://ello.co/davidohlerking">Ello</OutboundLink>
      </p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets2.ello.co/uploads/asset/attachment/15424223/ello-optimized-7d89f6d6.jpg"
        alt="David Ohlerking Art"
      />
      <p>Point Pleasant PA USA</p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets2.ello.co/uploads/asset/attachment/15420694/ello-optimized-35a15c5f.jpg"
        alt="David Ohlerking Art"
      />
      <p>James-Lorah Memorial Home Auditorium, Doylestown PA USA</p>
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/9476589/ello-optimized-e68f807c.jpg"
        alt="David Ohlerking Art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/8246828/ello-optimized-192372a2.jpg"
        alt="David Ohlerking Art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/8541231/ello-optimized-0f8148f1.jpg"
        alt="David Ohlerking Art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/6948251/ello-optimized-f3e0889c.jpg"
        alt="David Ohlerking Art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/9514530/ello-optimized-e6a4870b.jpg"
        alt="David Ohlerking Art"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/8432049/ello-optimized-48354b20.jpg"
        alt="David Ohlerking Art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/9963158/ello-optimized-b86b4e81.jpg"
        alt="David Ohlerking Art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/4618194/ello-optimized-ad516fa8.jpg"
        alt="David Ohlerking Art"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/9260033/ello-optimized-6929b353.jpg"
        alt="David Ohlerking Art"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15319948/ello-optimized-f0d5c0e6.jpg"
        alt="David Ohlerking Art"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/8629681/ello-optimized-878c93f0.jpg"
        alt="David Ohlerking Art"
      />
    </Layout>
  );
};

export default Page;
